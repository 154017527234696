<template>
  <header class="page-title">
    <h1><i class="bi bi-user-circle"></i> トークスクリプト HOT</h1>
  </header>

  <inline-loader v-if="loading"></inline-loader>
  <template v-else>
    <section class="section">
      <h2 class="section-title">お問い合わせ内容</h2>
      <div class="row mb-3">
        <div class="col-6">
          <div class="form-label">会社名</div>
          <div class="form-readonly">{{ project.customer.company_name }}</div>
        </div>
        <div class="col-6">
          <div class="form-label">担当者名</div>
          <div class="form-readonly">{{ primary_user.contact_name }}</div>
        </div>
        <div class="col-6">
          <div class="form-label">電話番号</div>
          <div class="form-readonly">{{ primary_user.phone }}</div>
        </div>
        <div class="col-12">
          <div class="form-label">メールアドレス</div>
          <div class="form-readonly">{{ primary_user.email }}</div>
        </div>
        <div class="col-6">
          <div class="form-label">チャネル</div>
          <div class="form-readonly">{{ project.channel.channel_name }}</div>
        </div>
      </div>
      <div class="row mb-3">
        <div class="col-36">
          <div class="form-label">資料請求</div>
          <div class="form-readonly">
            {{ project.form_document }}
          </div>
        </div>
        <div class="col-36">
          <div class="form-label">ご検討事項</div>
          <div class="form-readonly">
            {{ project.form_consideration }}
          </div>
        </div>
        <div class="col-36">
          <div class="form-label">ご希望のサービス内容</div>
          <div class="form-readonly">
            {{ project.form_request }}
          </div>
        </div>
        <div class="col-36">
          <div class="form-label">お問い合わせ内容</div>
          <div class="form-readonly">
            {{ project.form_inquiry }}
          </div>
        </div>
      </div>
    </section>

    <section class="section">
      <ul class="list-group">
        <li class="list-group-item">
          <p class="mb-3 p-3 bg-light">
            お世話になっております。<br />
            スリーエーコンサルティング・<strong>認証パートナー</strong>の<strong>{{ user.user_name }}</strong>と申します。<br />
            恐れ入りますが<strong>{{ primary_user.contact_name }}({{
              primary_user.contact_name_kana
            }})</strong>
            様はご在社でしょうか。
          </p>

          <div class="btn-group" role="group">
            <input type="radio" class="btn-check" id="btnradio1_1" v-model="q1" :value="1" />
            <label class="btn btn-outline-primary" for="btnradio1_1">いる</label>
            <input type="radio" class="btn-check" id="btnradio1_2" v-model="q1" :value="2" />
            <label class="btn btn-outline-primary" for="btnradio1_2">いない</label>
          </div>
        </li>

        <!-- Q1いない場合 -->
        <!-- 表示優先順位：ご検討事項 > ご希望のサービス内容 > お問い合わせ内容 -->
        <li class="list-group-item" v-if="q1 === 2">
          <div class="mb-3 p-3 bg-light">
            承知いたしました。<br />
            また改めてご連絡をさせていただきます。<br />
            「{{
              project.form_consideration
                ? project.form_consideration
                : project.form_request
                  ? project.form_request
                  : project.form_inquiry
                    ? project.form_inquiry
                    : "〇〇"
            }}」の件で<span class="text-danger fw-bold">連絡があった旨お伝えください。</span>（しっかり言い切る）<br />
            ※その後メールを送る
          </div>
          <div class="row my-3">
            <div class="col-18">
              <div class="form-label">メールアドレス</div>
              <form-input v-model="primary_user.email"></form-input>
            </div>
          </div>
          <div class="row my-3" v-if="q1 === 2">
            <div class="col-6">
              <label class="form-label">件名 <i class="required"></i></label>
              <form-select v-model="activity.activity_detailed_type" :options="ActivityDetailedType.approachOptions()"
                :required="true"></form-select>
            </div>
            <div class="col-6">
              <label class="form-label">ステータス</label>
              <form-select v-model="activity.status" :options="Status.options()"></form-select>
            </div>
          </div>
          <div class="row mb-3 align-items-end" v-if="q1 === 2">
            <div class="col-6">
              <label class="form-label">活動日 <i class="required"></i></label>
              <form-input-date v-model="activity.activity_date"></form-input-date>
            </div>
            <div class="col-6">
              <form-input-time v-model="activity.activity_time"></form-input-time>
            </div>
          </div>
        </li>

        <!-- Q1 いる場合 -->
        <!-- 表示優先順位：ご検討事項 > ご希望のサービス内容 > お問い合わせ内容 -->
        <li class="list-group-item" v-if="q1 === 1">
          <div class="mb-3 p-3 bg-light">
            この度
            <strong>{{ project.channel.channel_name }}</strong>
            にてお問い合わせいただきありがとうございます。<br />
            「{{
              project.form_consideration
                ? project.form_consideration
                : project.form_request
                  ? project.form_request
                  : project.form_inquiry
                    ? project.form_inquiry
                    : "〇〇"
            }}」の件でご連絡いたしました。
          </div>
        </li>

        <!-- Q1 いる場合 -->
        <li class="list-group-item" v-if="q1 === 1">
          <div class="mb-3 p-3 bg-light">
            (規格)の新規認証のご検討でお間違いないでしょうか。
          </div>

          <div class="row mt-3">
            <div class="col-12">
              <div class="form-label">規格</div>
              <form-select-search-multiple v-model="project.standards_values"
                :options="Standard.options()"></form-select-search-multiple>
            </div>
          </div>
          <div class="row mt-3">
            <div class="col-12">
              <div class="form-label">その他規格</div>
              <form-input class="form-control" v-model="project.standard_note"></form-input>
            </div>
          </div>
        </li>

        <!-- Q1 いる場合 -->
        <li class="list-group-item" v-if="q1 === 1">
          <div class="mb-3 p-3 bg-light">
            ありがとうございます。<br />
            弊社の(規格)担当者より無料相談という形でZOOMにて(お問合せ内容)の件についてご説明をさせていただければと存じます。<br />
          </div>

          <div class="btn-group" role="group">
            <input type="radio" class="btn-check" id="btnradio5_1" v-model="q2" :value="1" />
            <label class="btn btn-outline-primary" for="btnradio5_1">OK</label>
            <input type="radio" class="btn-check" id="btnradio5_2" v-model="q2" :value="2" />
            <label class="btn btn-outline-primary" for="btnradio5_2">情報収集、資料のみ希望</label>
            <input type="radio" class="btn-check" id="btnradio5_3" v-model="q2" :value="3" />
            <label class="btn btn-outline-primary" for="btnradio5_3">電話での即対応希望</label>
            <input type="radio" class="btn-check" id="btnradio5_4" v-model="q2" :value="4" />
            <label class="btn btn-outline-primary" for="btnradio5_4">いつでもいいから電話してほしい</label>
            <input type="radio" class="btn-check" id="btnradio5_5" v-model="q2" :value="5" />
            <label class="btn btn-outline-primary" for="btnradio5_5">Zoom？</label>
            <input type="radio" class="btn-check" id="btnradio5_6" v-model="q2" :value="6" />
            <label class="btn btn-outline-primary" for="btnradio5_6">見積もり希望</label>
          </div>
        </li>

        <!-- Q2 日時OKの場合 -->
        <li class="list-group-item" v-if="q1 === 1 && q2 === 1">
          <div class="mb-3 p-3 bg-light">
            直近でしたら〇月〇日〇時が空いておりますが、<br />
            そのお時間、<strong>{{ primary_user.contact_name }}</strong>
            様はいらっしゃいますか？
          </div>

          <div class="btn-group mt-3" role="group">
            <input type="radio" class="btn-check" id="btnradio6_1" v-model="q3" :value="1" />
            <label class="btn btn-outline-primary mb-3" for="btnradio6_1">その日でOK</label>
            <input type="radio" class="btn-check" id="btnradio6_2" v-model="q3" :value="2" />
            <label class="btn btn-outline-primary mb-3" for="btnradio6_2">やっぱり打ち合わせ辞めておきます</label>
          </div>

          <div class="row" v-if="q1 === 1 && q3 === 1">
            <div class="col-12">
              <div class="form-label">アポ日時</div>
              <form-input-date v-model="activity.appointment_datetime"></form-input-date>
            </div>
            <div class="col-6">
              <div class="form-label">営業担当</div>
              <form-select-search v-model="activity.sales_user.user_id" :options="options_user"
                placeholder="----"></form-select-search>
            </div>
            <div class="my-3 p-3 bg-light">
              では{{
                activity.appointment_datetime
                  ? dateMd(activity.appointment_datetime)
                  : "〇月〇日〇時"
              }}に弊社の{{
                activity.sales_user.user_id ? selectedSalesUserName : "〇〇"
              }}がお話させていただきます。
            </div>
            <div class="mb-3 p-3 bg-light">
              それではお電話切らせていただいた後にメールにて当日使用いたしますZOOMURLをお送りいたします。<br />
              お手すきの際にご確認をお願いいたします。<br />
              <br />
              当日は何卒よろしくお願いいたします。 失礼いたします。
            </div>
            <div class="row mt-3">
              <div class="col-18">
                <div class="form-label">メールアドレス</div>
                <form-input v-model="primary_user.email"></form-input>
              </div>
            </div>
            <div class="row mb-3">
              <div class="col-6 mt-5">
                <form-checkbox v-model="activity.is_tradable" option_label="取引可"></form-checkbox>
              </div>
            </div>
            <div class="row mb-3 align-items-end">
              <div class="col-6">
                <label class="form-label">活動日 <i class="required"></i></label>
                <form-input-date v-model="activity.activity_date"></form-input-date>
              </div>
              <div class="col-6">
                <form-input-time v-model="activity.activity_time"></form-input-time>
              </div>
            </div>
          </div>

          <!-- Q3 やっぱり辞めておくの場合 -->
          <div class="mb-3 p-3 bg-light" v-if="q1 === 1 && q3 === 2">
            ①無料相談に関してご契約有無に関わらず実施しておりますのでご説明の上で、導入が難しいと思った場合でもお断りいただいても問題ございません。<br />
            <br />
            ②導入の有無については、{{
              primary_user.contact_name
            }}様のご判断ですので、情報提供という意味でもより良い時間をご提供できれば十分です。<br />
          </div>
          <div class="row my-3" v-if="q1 === 1 && q3 === 2">
            <div class="col-6">
              <label class="form-label">件名 <i class="required"></i></label>
              <form-select v-model="activity.activity_detailed_type" :options="ActivityDetailedType.approachOptions()"
                :required="true"></form-select>
            </div>
            <div class="col-6">
              <label class="form-label">ステータス</label>
              <form-select v-model="activity.status" :options="Status.options()"></form-select>
            </div>
          </div>
          <div class="row mb-3 align-items-end" v-if="q1 === 1 && q3 === 2">
            <div class="col-6">
              <label class="form-label">活動日 <i class="required"></i></label>
              <form-input-date v-model="activity.activity_date"></form-input-date>
            </div>
            <div class="col-6">
              <form-input-time v-model="activity.activity_time"></form-input-time>
            </div>
          </div>
        </li>

        <!-- Q2 情報収集、資料のみ希望の場合 -->
        <li class="list-group-item" v-if="q1 === 1 && q2 === 2">
          <div class="mb-3 p-3 bg-light">
            先に資料ほしいは送ってOK<br />
            <br />
            ①（規格）について、現場に出ている担当者が30分程度でご説明いたしますので情報収集として、ぜひ一度お話を聞いて下さいませ。<br />
            <br />
            ②ありがとうございます。<br />
            資料をお送りさせて頂きますが、簡単なものになりますので、ご挨拶もかねて詳しく情報提供できればと思います。<br />
            来週の〇曜日、もしくは〇曜日どちらがよろしいでしょうか？<br />
            ※お断りされた場合はメールで資料を送る
          </div>

          <div class="btn-group mt-3" role="group">
            <input type="radio" class="btn-check" id="btnradio6_1" v-model="q4" :value="1" />
            <label class="btn btn-outline-primary mb-3" for="btnradio6_1">打ち合わせ希望</label>
            <input type="radio" class="btn-check" id="btnradio6_2" v-model="q4" :value="2" />
            <label class="btn btn-outline-primary mb-3" for="btnradio6_2">打ち合わせお断り</label>
          </div>

          <div class="row" v-if="q1 === 1 && q4 === 1">
            <div class="col-12">
              <div class="form-label">アポ日時</div>
              <form-input-date v-model="activity.appointment_datetime"></form-input-date>
            </div>
            <div class="col-6">
              <div class="form-label">営業担当</div>
              <form-select-search v-model="activity.sales_user.user_id" :options="options_user"
                placeholder="----"></form-select-search>
            </div>
            <div class="my-3 p-3 bg-light">
              では{{
                activity.appointment_datetime
                  ? dateMd(activity.appointment_datetime)
                  : "〇月〇日〇時"
              }}に弊社の{{
                activity.sales_user.user_id ? selectedSalesUserName : "〇〇"
              }}がお話させていただきます。
            </div>
            <div class="mb-3 p-3 bg-light">
              それではお電話切らせていただいた後にメールにて当日使用いたしますZOOMURLをお送りいたします。<br />
              お手すきの際にご確認をお願いいたします。<br />
              <br />
              当日は何卒よろしくお願いいたします。 失礼いたします。
            </div>
            <div class="row my-3">
              <div class="col-18">
                <div class="form-label">メールアドレス</div>
                <form-input v-model="primary_user.email"></form-input>
              </div>
            </div>
            <div class="row my-3">
              <div class="col-6">
                <label class="form-label">件名 <i class="required"></i></label>
                <form-select v-model="activity.activity_detailed_type" :options="ActivityDetailedType.approachOptions()"
                  :required="true"></form-select>
              </div>
              <div class="col-6 mt-5">
                <form-checkbox v-model="activity.is_tradable" option_label="取引可"></form-checkbox>
              </div>
            </div>
            <div class="row mb-3 align-items-end">
              <div class="col-6">
                <label class="form-label">活動日 <i class="required"></i></label>
                <form-input-date v-model="activity.activity_date"></form-input-date>
              </div>
              <div class="col-6">
                <form-input-time v-model="activity.activity_time"></form-input-time>
              </div>
            </div>
          </div>

          <div class="row" v-if="q1 === 1 && q4 === 2">
            <div class="col-18">
              <div class="mb-3 p-3 bg-light">
                承知いたしました。<br />
                資料をメールで送付させていただきます。<br />
                ※その後メールを送る
              </div>
              <div class="row my-3">
                <div class="form-label">メールアドレス</div>
                <form-input v-model="primary_user.email"></form-input>
              </div>
            </div>
          </div>
          <div class="row my-3" v-if="q1 === 1 && q4 === 2">
            <div class="col-6">
              <label class="form-label">件名 <i class="required"></i></label>
              <form-select v-model="activity.activity_detailed_type" :options="ActivityDetailedType.approachOptions()"
                :required="true"></form-select>
            </div>
            <div class="col-6">
              <label class="form-label">ステータス</label>
              <form-select v-model="activity.status" :options="Status.options()"></form-select>
            </div>
          </div>
          <div class="row mb-3 align-items-end" v-if="q1 === 1 && q4 === 2">
            <div class="col-6">
              <label class="form-label">活動日 <i class="required"></i></label>
              <form-input-date v-model="activity.activity_date"></form-input-date>
            </div>
            <div class="col-6">
              <form-input-time v-model="activity.activity_time"></form-input-time>
            </div>
          </div>
        </li>

        <!-- Q2 電話即希望の場合 -->
        <li class="list-group-item" v-if="q1 === 1 && q2 === 3">
          <div class="mb-3 p-3 bg-light">
            ・アポイント獲得部で検討状況確認し、営業マン調整させてもらう事実を伝えてOK<br />
            ・電話対応時に担当が見つからなくても、「この後担当から電話します」と伝え<br />
            一旦落ち着いてもらうアプローチをする<br />
            ・電話切った後、対応出来る営業マンを見つけて対応してもらう
          </div>

          <div class="btn-group mt-3" role="group">
            <input type="radio" class="btn-check" id="btnradio6_1" v-model="q5" :value="1" />
            <label class="btn btn-outline-primary mb-3" for="btnradio6_1">即対応可能</label>
            <input type="radio" class="btn-check" id="btnradio6_2" v-model="q5" :value="2" />
            <label class="btn btn-outline-primary mb-3" for="btnradio6_2">営業担当不在</label>
          </div>

          <div class="row" v-if="q1 === 1 && q5 === 1">
            <div class="col-12">
              <div class="form-label">アポ日時</div>
              <form-input-date v-model="activity.appointment_datetime"></form-input-date>
            </div>
            <div class="col-6">
              <div class="form-label">営業担当</div>
              <form-select-search v-model="activity.sales_user.user_id" :options="options_user"
                placeholder="----"></form-select-search>
            </div>
            <div class="row my-3">
              <div class="col-6">
                <label class="form-label">件名 <i class="required"></i></label>
                <form-select v-model="activity.activity_detailed_type" :options="ActivityDetailedType.approachOptions()"
                  :required="true"></form-select>
              </div>
              <div class="col-6 mt-5">
                <form-checkbox v-model="activity.is_tradable" option_label="取引可"></form-checkbox>
              </div>
            </div>
            <div class="row mb-3 align-items-end">
              <div class="col-6">
                <label class="form-label">活動日 <i class="required"></i></label>
                <form-input-date v-model="activity.activity_date"></form-input-date>
              </div>
              <div class="col-6">
                <form-input-time v-model="activity.activity_time"></form-input-time>
              </div>
            </div>
          </div>

          <div class="row" v-if="q1 === 1 && q5 === 2">
            <div class="my-3 p-3 bg-light">
              この後担当から電話します<br />
              ・電話切った後、対応出来る営業マンを見つけて対応してもらう
            </div>
          </div>
          <div class="row my-3" v-if="q1 === 1 && q5 === 2">
            <div class="col-6">
              <label class="form-label">件名 <i class="required"></i></label>
              <form-select v-model="activity.activity_detailed_type" :options="ActivityDetailedType.approachOptions()"
                :required="true"></form-select>
            </div>
            <div class="col-6">
              <label class="form-label">ステータス</label>
              <form-select v-model="activity.status" :options="Status.options()"></form-select>
            </div>
          </div>
          <div class="row mb-3 align-items-end" v-if="q1 === 1 && q5 === 2">
            <div class="col-6">
              <label class="form-label">活動日 <i class="required"></i></label>
              <form-input-date v-model="activity.activity_date"></form-input-date>
            </div>
            <div class="col-6">
              <form-input-time v-model="activity.activity_time"></form-input-time>
            </div>
          </div>
        </li>

        <!-- Q2 いつでもいいから電話しての場合 -->
        <li class="list-group-item" v-if="q1 === 1 && q2 === 4">
          <div class="mb-3 p-3 bg-light">
            担当者も現場にでているので、<br />
            日程を抑えさせていただいてこちらからお電話しております。<br />
            〇日はいかがでしょうか。<br />
            ※営業マンの社用携帯は教えない
          </div>

          <div class="btn-group mt-3" role="group">
            <input type="radio" class="btn-check" id="btnradio6_1" v-model="q6" :value="1" />
            <label class="btn btn-outline-primary mb-3" for="btnradio6_1">その日でOK</label>
            <input type="radio" class="btn-check" id="btnradio6_2" v-model="q6" :value="2" />
            <label class="btn btn-outline-primary mb-3" for="btnradio6_2">やっぱり打ち合わせ辞めておきます</label>
          </div>

          <div class="row" v-if="q1 === 1 && q6 === 1">
            <div class="col-12">
              <div class="form-label">アポ日時</div>
              <form-input-date v-model="activity.appointment_datetime"></form-input-date>
            </div>
            <div class="col-6">
              <div class="form-label">営業担当</div>
              <form-select-search v-model="activity.sales_user.user_id" :options="options_user"
                placeholder="----"></form-select-search>
            </div>
            <div class="my-3 p-3 bg-light">
              では{{
                activity.appointment_datetime
                  ? dateMd(activity.appointment_datetime)
                  : "〇月〇日〇時"
              }}に弊社の{{
                activity.sales_user.user_id ? selectedSalesUserName : "〇〇"
              }}がお電話させていただきます。
            </div>
            <div class="mb-3 p-3 bg-light">
              当日は何卒よろしくお願いいたします。 失礼いたします。
            </div>
            <div class="row my-3">
              <div class="col-6">
                <label class="form-label">件名 <i class="required"></i></label>
                <form-select v-model="activity.activity_detailed_type" :options="ActivityDetailedType.approachOptions()"
                  :required="true"></form-select>
              </div>
              <div class="col-6 mt-5">
                <form-checkbox v-model="activity.is_tradable" option_label="取引可"></form-checkbox>
              </div>
            </div>
            <div class="row mb-3 align-items-end">
              <div class="col-6">
                <label class="form-label">活動日 <i class="required"></i></label>
                <form-input-date v-model="activity.activity_date"></form-input-date>
              </div>
              <div class="col-6">
                <form-input-time v-model="activity.activity_time"></form-input-time>
              </div>
            </div>
          </div>

          <!-- Q3 やっぱり辞めておくの場合 -->
          <div class="mb-3 p-3 bg-light" v-if="q1 === 1 && q6 === 2">
            ①無料相談に関してご契約有無に関わらず実施しておりますのでご説明の上で、導入が難しいと思った場合でもお断りいただいても問題ございません。<br />
            <br />
            ②導入の有無については、{{
              primary_user.contact_name
            }}様のご判断ですので、情報提供という意味でもより良い時間をご提供できれば十分です。<br />
          </div>
          <div class="row my-3" v-if="q1 === 1 && q6 === 2">
            <div class="col-6">
              <label class="form-label">件名 <i class="required"></i></label>
              <form-select v-model="activity.activity_detailed_type" :options="ActivityDetailedType.approachOptions()"
                :required="true"></form-select>
            </div>
            <div class="col-6">
              <label class="form-label">ステータス</label>
              <form-select v-model="activity.status" :options="Status.options()"></form-select>
            </div>
          </div>
          <div class="row mb-3 align-items-end" v-if="q1 === 1 && q6 === 2">
            <div class="col-6">
              <label class="form-label">活動日 <i class="required"></i></label>
              <form-input-date v-model="activity.activity_date"></form-input-date>
            </div>
            <div class="col-6">
              <form-input-time v-model="activity.activity_time"></form-input-time>
            </div>
          </div>
        </li>

        <!-- Q2 Zoomとは？の場合 -->
        <li class="list-group-item" v-if="q1 === 1 && q2 === 5">
          <div class="my-3 p-3 bg-light">
            ビデオ通話なんですけど、お持ちのPCにカメラはついていますか？<br />
            ZOOMというビデオ通話機能があり、<br />
            操作方法も分かりやすいのでこのあとメールで手順書をお送りします。<br />
            もし難しそうならお電話で対応します
          </div>

          <div class="btn-group mt-3" role="group">
            <input type="radio" class="btn-check" id="btnradio6_1" v-model="q7" :value="1" />
            <label class="btn btn-outline-primary mb-3" for="btnradio6_1">Zoomで打ち合わせ</label>
            <input type="radio" class="btn-check" id="btnradio6_2" v-model="q7" :value="2" />
            <label class="btn btn-outline-primary mb-3" for="btnradio6_2">電話対応希望</label>
            <input type="radio" class="btn-check" id="btnradio6_3" v-model="q7" :value="3" />
            <label class="btn btn-outline-primary mb-3" for="btnradio6_3">やっぱり打ち合わせ辞めておきます</label>
          </div>

          <div class="row" v-if="q1 === 1 && q7 === 1">
            <div class="mb-3 p-3 bg-light">
              直近でしたら〇月〇日〇時が空いておりますが、<br />
              そのお時間、{{ primary_user.contact_name }}様はいかがでしょうか？
            </div>
            <div class="col-12">
              <div class="form-label">アポ日時</div>
              <form-input-date v-model="activity.appointment_datetime"></form-input-date>
            </div>
            <div class="col-6">
              <div class="form-label">営業担当</div>
              <form-select-search v-model="activity.sales_user.user_id" :options="options_user"
                placeholder="----"></form-select-search>
            </div>
            <div class="my-3 p-3 bg-light">
              では{{
                activity.appointment_datetime
                  ? dateMd(activity.appointment_datetime)
                  : "〇月〇日〇時"
              }}に弊社の{{
                activity.sales_user.user_id ? selectedSalesUserName : "〇〇"
              }}がお話させていただきます。
            </div>
            <div class="mb-3 p-3 bg-light">
              それではお電話切らせていただいた後にメールにて当日使用いたしますZOOMURLをお送りいたします。<br />
              お手すきの際にご確認をお願いいたします。<br />
              <br />
              当日は何卒よろしくお願いいたします。 失礼いたします。
            </div>
            <div class="row my-3">
              <div class="col-18">
                <div class="form-label">メールアドレス</div>
                <form-input v-model="primary_user.email"></form-input>
              </div>
            </div>
            <div class="row my-3">
              <div class="col-6">
                <label class="form-label">件名 <i class="required"></i></label>
                <form-select v-model="activity.activity_detailed_type" :options="ActivityDetailedType.approachOptions()"
                  :required="true"></form-select>
              </div>
              <div class="col-6 mt-5">
                <form-checkbox v-model="activity.is_tradable" option_label="取引可"></form-checkbox>
              </div>
            </div>
            <div class="row mb-3 align-items-end">
              <div class="col-6">
                <label class="form-label">活動日 <i class="required"></i></label>
                <form-input-date v-model="activity.activity_date"></form-input-date>
              </div>
              <div class="col-6">
                <form-input-time v-model="activity.activity_time"></form-input-time>
              </div>
            </div>
          </div>

          <div class="row" v-if="q1 === 1 && q7 === 2">
            <div class="mb-3 p-3 bg-light">
              この後担当から電話します<br />
              ・電話切った後、対応出来る営業マンを見つけて対応してもらう
            </div>
            <div class="row my-3" v-if="q1 === 1 && q7 === 2">
              <div class="col-6">
                <label class="form-label">件名 <i class="required"></i></label>
                <form-select v-model="activity.activity_detailed_type" :options="ActivityDetailedType.approachOptions()"
                  :required="true"></form-select>
              </div>
              <div class="col-6">
                <label class="form-label">ステータス</label>
                <form-select v-model="activity.status" :options="Status.options()"></form-select>
              </div>
            </div>
            <div class="row mb-3 align-items-end" v-if="q1 === 1 && q7 === 2">
              <div class="col-6">
                <label class="form-label">活動日 <i class="required"></i></label>
                <form-input-date v-model="activity.activity_date"></form-input-date>
              </div>
              <div class="col-6">
                <form-input-time v-model="activity.activity_time"></form-input-time>
              </div>
            </div>
          </div>

          <!-- Q3 やっぱり辞めておくの場合 -->
          <div class="mb-3 p-3 bg-light" v-if="q1 === 1 && q7 === 3">
            ①無料相談に関してご契約有無に関わらず実施しておりますのでご説明の上で、導入が難しいと思った場合でもお断りいただいても問題ございません。<br />
            <br />
            ②導入の有無については、{{
              primary_user.contact_name
            }}様のご判断ですので、情報提供という意味でもより良い時間をご提供できれば十分です。<br />
          </div>
          <div class="row my-3" v-if="q1 === 1 && q7 === 3">
            <div class="col-6">
              <label class="form-label">件名 <i class="required"></i></label>
              <form-select v-model="activity.activity_detailed_type" :options="ActivityDetailedType.approachOptions()"
                :required="true"></form-select>
            </div>
            <div class="col-6">
              <label class="form-label">ステータス</label>
              <form-select v-model="activity.status" :options="Status.options()"></form-select>
            </div>
          </div>
          <div class="row mb-3 align-items-end" v-if="q1 === 1 && q7 === 3">
            <div class="col-6">
              <label class="form-label">活動日 <i class="required"></i></label>
              <form-input-date v-model="activity.activity_date"></form-input-date>
            </div>
            <div class="col-6">
              <form-input-time v-model="activity.activity_time"></form-input-time>
            </div>
          </div>
        </li>

        <!-- Q2 見積もり希望の場合 -->
        <li class="list-group-item" v-if="q1 === 1 && q2 === 6">
          <div class="mb-3 p-3 bg-light">
            お見積りについて、正式な金額となりますのでミスコミュニケーションを防ぐため<br />
            一度ZOOMにてお打ち合わせをさせていただいてから作成できればと思います。<br />
            <br />
            直近でしたら〇月〇日〇時が空いておりますが、<br />
            そのお時間、{{ primary_user.contact_name }}様はいらっしゃいますか？
          </div>

          <div class="btn-group mt-3" role="group">
            <input type="radio" class="btn-check" id="btnradio6_1" v-model="q8" :value="1" />
            <label class="btn btn-outline-primary mb-3" for="btnradio6_1">その日でOK</label>
            <input type="radio" class="btn-check" id="btnradio6_2" v-model="q8" :value="2" />
            <label class="btn btn-outline-primary mb-3" for="btnradio6_2">やっぱり打ち合わせ辞めておきます</label>
          </div>

          <div class="row" v-if="q1 === 1 && q8 === 1">
            <div class="col-12">
              <div class="form-label">アポ日時</div>
              <form-input-date v-model="activity.appointment_datetime"></form-input-date>
            </div>
            <div class="col-6">
              <div class="form-label">営業担当</div>
              <form-select-search v-model="activity.sales_user.user_id" :options="options_user"
                placeholder="----"></form-select-search>
            </div>
            <div class="my-3 p-3 bg-light">
              では{{
                activity.appointment_datetime
                  ? dateMd(activity.appointment_datetime)
                  : "〇月〇日〇時"
              }}に弊社の{{
                activity.sales_user.user_id ? selectedSalesUserName : "〇〇"
              }}がお話させていただきます。
            </div>
            <div class="mb-3 p-3 bg-light">
              それではお電話切らせていただいた後にメールにて当日使用いたしますZOOMURLをお送りいたします。<br />
              お手すきの際にご確認をお願いいたします。<br />
              <br />
              当日は何卒よろしくお願いいたします。 失礼いたします。
            </div>
            <div class="row my-3">
              <div class="col-18">
                <div class="form-label">メールアドレス</div>
                <form-input v-model="primary_user.email"></form-input>
              </div>
            </div>
            <div class="row my-3">
              <div class="col-6">
                <label class="form-label">件名 <i class="required"></i></label>
                <form-select v-model="activity.activity_detailed_type" :options="ActivityDetailedType.approachOptions()"
                  :required="true"></form-select>
              </div>
              <div class="col-6 mt-5">
                <form-checkbox v-model="activity.is_tradable" option_label="取引可"></form-checkbox>
              </div>
            </div>
            <div class="row mb-3 align-items-end">
              <div class="col-6">
                <label class="form-label">活動日 <i class="required"></i></label>
                <form-input-date v-model="activity.activity_date"></form-input-date>
              </div>
              <div class="col-6">
                <form-input-time v-model="activity.activity_time"></form-input-time>
              </div>
            </div>
          </div>

          <!-- Q3 やっぱり辞めておくの場合 -->
          <div class="mb-3 p-3 bg-light" v-if="q1 === 1 && q8 === 2">
            ①無料相談に関してご契約有無に関わらず実施しておりますのでご説明の上で、導入が難しいと思った場合でもお断りいただいても問題ございません。<br />
            <br />
            ②導入の有無については、{{
              primary_user.contact_name
            }}様のご判断ですので、情報提供という意味でもより良い時間をご提供できれば十分です。<br />
          </div>
          <div class="row my-3" v-if="q1 === 1 && q8 === 2">
            <div class="col-6">
              <label class="form-label">件名 <i class="required"></i></label>
              <form-select v-model="activity.activity_detailed_type" :options="ActivityDetailedType.approachOptions()"
                :required="true"></form-select>
            </div>
            <div class="col-6">
              <label class="form-label">ステータス</label>
              <form-select v-model="activity.status" :options="Status.options()"></form-select>
            </div>
          </div>
          <div class="row mb-3 align-items-end" v-if="q1 === 1 && q8 === 2">
            <div class="col-6">
              <label class="form-label">活動日 <i class="required"></i></label>
              <form-input-date v-model="activity.activity_date"></form-input-date>
            </div>
            <div class="col-6">
              <form-input-time v-model="activity.activity_time"></form-input-time>
            </div>
          </div>
        </li>
      </ul>

      <!-- <div class="row mt-5">
        <div class="col-18">
          <div class="form-label">特記事項</div>
          <form-textarea class="form-control" rows="5"></form-textarea>
        </div>
      </div> -->

      <div class="mt-5">
        <button class="btn btn-info btn-lg" @click="createActivity">活動履歴に反映</button>
      </div>
    </section>
  </template>
</template>

<script>
import Project from "@/models/entities/project";
import ProjectContact from "@/models/entities/project-contact";
import User from "@/models/entities/user";
import Activity from "@/models/entities/activity";
import InlineLoader from "@/components/tools/InlineLoader";
import ContactType from "@/models/enums/contact-type";
import Standard from "@/models/enums/standard";
import FormSelectSearchMultiple from "@/components/forms/FormSelectSearchMultiple";
import FormSelectSearch from "@/components/forms/FormSelectSearch";
import FormInput from "@/components/forms/FormInput";
import FormInputDate from "@/components/forms/FormInputDate.vue";
import FormInputTime from "@/components/forms/FormInputTime.vue";
import FormSelect from '@/components/forms/FormSelect.vue'
import FormCheckbox from '@/components/forms/FormCheckbox.vue'
import { dateMd, dateFormat } from "@/utilities/date-format";
import ActivityType from '@/models/enums/activity-type';
import ActivityDetailedType from '@/models/enums/activity-detailed-type'
import Status from "@/models/enums/status";

export default {
  name: "Talker",
  components: {
    InlineLoader,
    FormSelectSearchMultiple,
    FormInput,
    FormSelectSearch,
    FormInputDate,
    FormInputTime,
    FormSelect,
    FormCheckbox
  },
  inject: [
    "startScreenLoading",
    "endScreenLoading",
    "showErrorMessage",
    "showMessage",
    "setTitle",
  ],
  data() {
    return {
      // ローディング
      loading: false,
      // パスパラメータproject_id
      param_project_id: parseInt(this.$route.params.id, 10),

      project: new Project(),
      activity: new Activity(),
      primary_user: new ProjectContact(),
      user: new User(),

      q1: null,
      q2: null,
      q3: null,
      q4: null,
      q5: null,
      q6: null,
      q7: null,
      q8: null,

      // enum
      ContactType,
      Standard,
      ActivityType,
      ActivityDetailedType,
      Status,

      // 営業担当オプション
      options_user: [],

      // 日付
      dateMd,
      dateFormat,
    };
  },
  mounted: async function () {
    await this.init();
    await this.fetchLoginUser();
    await this.fetchUsers();
  },
  computed: {
    selectedSalesUserName() {
      const match = this.options_user.find(
        (user) => user.value === this.activity.sales_user.user_id
      );
      return match ? match.label : "〇〇";
    },
  },
  methods: {
    async init() {
      this.loading = true;

      try {
        const response = await this.$http.get(
          `/project/${this.param_project_id}`
        );
        this.project = new Project(response.data);
        this.setTitle(this.project.customer.company_name);

        if (this.project.contacts.length > 0) {
          const primary_user = this.project.contacts.find(
            (contact) => contact.contact_type === this.ContactType.PRIMARY
          );
          this.primary_user = new ProjectContact(primary_user);
        }
      } catch (error) {
        this.showErrorMessage("案件が存在しません");
        if (error.response && error.response.status === 404) {
          this.$router.push({ name: "Project" });
        }
      } finally {
        this.loading = false;
      }
    },
    // ログインユーザ取得
    fetchLoginUser() {
      return new Promise((resolve) => {
        this.$http
          .get("/me")
          .then((response) => {
            this.user = new User(response.data);
          })
          .finally(() => {
            resolve(true);
          });
      });
    },
    // ユーザマスタ取得
    fetchUsers() {
      return new Promise((resolve) => {
        this.$http
          .get("/user")
          .then((response) => {
            for (let row of response.data) {
              let user = new User(row);
              this.options_user.push({
                value: user.user_id,
                label: user.user_name,
              });
            }
          })
          .finally(() => {
            resolve(true);
          });
      });
    },
    // 活動履歴に反映
    createActivity() {
      this.startScreenLoading();
      // 在社&その日でOK or 打ち合わせ希望 or zoomで打ち合わせ&アポ日時を記載 => アプローチ記録:1、アポ取得報告:2
      if (this.q1 === 2) {
        // 担当者不在
        this.activity.activity_type = ActivityType.APPROACH;
      } else if (this.q1 === ActivityType.APPROACH) {
        if (this.q3 === ActivityType.APPROACH) {
          this.activity.activity_type = ActivityType.APPOINTMENT; // アポ取得
          this.activity.activity_detailed_type = ActivityDetailedType.APPOINTMENT;
        } else if (this.q4 === ActivityType.APPROACH) {
          this.activity.activity_type = ActivityType.APPOINTMENT; // 資料送付＋アポ
          this.activity.activity_detailed_type = ActivityDetailedType.APPOINTMENT;
        } else if (this.q7 === ActivityType.APPROACH) {
          this.activity.activity_type = ActivityType.APPOINTMENT; // Zoom予約
          this.activity.activity_detailed_type = ActivityDetailedType.APPOINTMENT;
        } else {
          this.activity.activity_type = ActivityType.APPROACH; // 在社だがその他（辞退等）
        }
      }

      this.activity.user.user_id = this.user.user_id;
      this.activity.company_name = this.project.customer.company_name;
      this.activity.contact_name = this.primary_user.contact_name;

      try {
        this.$http.put(`/project/${this.project.project_id}`, this.project)
        this.$http.post(`/project/${this.project.project_id}/activity`, this.activity)
        this.showMessage('案件情報を保存しました');
      } finally {
        this.endScreenLoading();
        this.q1 = null,
          this.q2 = null,
          this.q3 = null,
          this.q4 = null,
          this.q5 = null,
          this.q6 = null,
          this.q7 = null,
          this.q8 = null,
          this.init();
      }
    }
  },
};
</script>

<style scoped></style>
